<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">超级管理员</span></div>
    <div class="wrapContent">
      <div class="data-hd">
        <p>超级管理员信息<span class="desc_info">超级管理员将接收开户信息及完成签约，并可进行商户号的日常重要管理及资金操作。请确定超级管理员为商户法定代表人或经办人再进行操作。</span></p>
      </div>
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="132px" :rules="formRules" size="medium">
          <el-form-item label="超管身份" prop="contactType">
            <el-radio v-model="form.contactType" label="LEGAL">经营者/法人</el-radio>
            <el-radio v-model="form.contactType" label="SUPER">经办人</el-radio>
          </el-form-item>
          <el-form-item label="证件类型" prop="contactIdDocType">
            <el-select v-model="form.contactIdDocType" placeholder="请选择">
              <el-option
                  v-for="item in idDocOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件人像面照片" prop="contactIdDocCopy">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleIdContactIdDocCopySuccess"
            >
              <el-image fit="contain" v-if="contactIdDocCopy" :src="contactIdDocCopy" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="证件国徽面照片" prop="contactIdDocCopyBack">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleIdCopyBackSuccess"
            >
              <el-image fit="contain" v-if="contactIdDocCopyBack" :src="contactIdDocCopyBack" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="证件号码" prop="contactIdNumber">
            <el-input v-model="form.contactIdNumber"></el-input>
          </el-form-item>
          <el-form-item label="证件持有人姓名" prop="contactName">
            <el-input v-model="form.contactName"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期类型" prop="cardTime">
            <el-radio v-model="cardTime" label="定期">定期</el-radio>
            <el-radio v-model="cardTime" label="长期">长期</el-radio>
          </el-form-item>
          <el-form-item label="证件生效日期" prop="contactPeriodBegin">
            <el-date-picker
                v-model="form.contactPeriodBegin"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="证件失效日期" prop="contactPeriodEnd" v-if="cardTime == '定期'">
            <el-date-picker
                v-model="form.contactPeriodEnd"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="业务办理授权函" prop="business_authorization_letter">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleIdBusinessAuthorizationLetterSuccess"
            >
              <el-image fit="contain" v-if="businessAuthorizationLetter" :src="businessAuthorizationLetter" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="el-form-info">
              <p>1.请上传法定代表人授权函扫描件<a href="https://kf.qq.com/faq/220509Y3Yvym220509fQvYR7.html" target="_blank">（下载模板）</a></p>
              <p>2.请上传2M以内的图片</p>
            </div>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobilePhone">
            <el-input v-model="form.mobilePhone"></el-input>
            <div class="el-form-info">
              <p>用于接收微信支付的重要管理信息及日常操作验证码，超管签约时可自助修改手机号，并进行短信验证<a href="https://kf.qq.com/faq/200703rEFN3U200703zeaeQN.html" target="_blank" class="gapl">超管修改手机号指引</a></p>
            </div>
          </el-form-item>
          <el-form-item label="邮箱" prop="contactEmail">
            <el-input v-model="form.contactEmail"></el-input>
            <div class="el-form-info">
              <p>用于接收微信支付的开户邮件及日常业务通知</p>
            </div>
          </el-form-item>
        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" @click="saveInfo" v-if="isWrite == true && isSee == 'false'">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext" v-if="isWrite == true && isSee == 'false'">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addContactInfo, getBankAccountInfo, getContactInfo} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";

export default {
  name: "contactInfo",
  components: {},
  data() {
    var validateIdCardNational = (rule, value, callback) => {
      if (!this.contactIdDocCopyBack) {
        callback(new Error('请上传证件国徽面照片'))
      } else {
        callback()
      }
    }
    var validateIdDocCopy = (rule, value, callback) => {
      if (!this.contactIdDocCopy) {
        callback(new Error('请上传证件人像面照片'))
      } else {
        callback()
      }
    }
    var validateLetter = (rule, value, callback) => {
      if (!this.businessAuthorizationLetter) {
        callback(new Error('请上传业务办理授权函'))
      } else {
        callback()
      }
    }
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      options:[
        {
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }
      ],
      idDocOption:[
        {
          value: 'IDENTIFICATION_TYPE_IDCARD',
          label: '中国大陆居民-身份证'
        }, {
          value: 'IDENTIFICATION_TYPE_OVERSEA_PASSPORT',
          label: '其他国家或地区居民-护照'
        },
        {
          value: 'IDENTIFICATION_TYPE_HONGKONG_PASSPORT',
          label: '中国香港居民-来往内地通行证'
        }, {
          value: 'IDENTIFICATION_TYPE_MACAO_PASSPORT',
          label: '中国澳门居民-来往内地通行证'
        }, {
          value: 'IDENTIFICATION_TYPE_TAIWAN_PASSPORT',
          label: '中国台湾居民-来往大陆通行证'
        }, {
          value: 'IDENTIFICATION_TYPE_FOREIGN_RESIDENT',
          label: '外国人居留证'
        }, {
          value: 'IDENTIFICATION_TYPE_HONGKONG_MACAO_RESIDENT',
          label: '港澳居民证'
        }, {
          value: 'IDENTIFICATION_TYPE_TAIWAN_RESIDENT',
          label: '台湾居民证'
        }
      ],
      dialogImageUrl: '',
      indoorPicFileList:[],
      dialogVisible: false,
      form:{
        contactType:'SUPER'
      },
      cardTime:'定期',
      isWrite:false,
      isSee:false,
      contactIdDocCopy:'',
      contactIdDocCopyBack:'',
      businessAuthorizationLetter:'',
      merchantId:'',
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      formRules: {
        contactType:[
          { required: true, message: '请选择超管身份', trigger: 'change' },
        ],
        contactIdDocType:[
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        contactIdNumber:[
          {  required: true, message: '请填写证件号码', trigger: 'change' }
        ],
        contactName:[
          { required: true, message: '请填写证件持有人姓名', trigger: 'blur' },
        ],
        contactIdDocCopyBack:[
          {validator:validateIdCardNational, trigger: 'change' },
        ],
        contactIdDocCopy:[
          {validator:validateIdDocCopy, trigger: 'change' },
        ],
        'businessAuthorizationLetter':[
          {validator:validateLetter, trigger: 'change' },
        ],
        contactPeriodBegin:[
          { required: true, message: '请选择证件生效日期', trigger: 'blur' },
        ],
        contactPeriodEnd:[
          { required: true, message: '请选择证件失效日期', trigger: 'blur' },
        ],
        mobilePhone:[
          { required: true, message: '请填写手机号码', trigger: 'blur' },
        ],
        contactEmail:[
          { required: true, message: '请填写邮箱', trigger: 'blur' },
        ]

      }
    };

  },
  computed: {},
  filters:{

  },
  watch: {},
  mounted() {
  },
  created() {
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.merchantId = this.$route.query.merchantId
    this.isWrite = this.$route.query.isWrite
    this.isSee = this.$route.query.isSee
    if (this.isWrite == 'true'){
      this.isWrite = true
    }else {
      if (this.isSee == 'false'){
        this.$message({
          message: '请先填写上一模块信息',
          type: 'warning',
          duration:2000
        });
      }
    }
    this.getContactInfo()
  },
  methods: {
    getContactInfo(){
      getContactInfo({merchantId:this.merchantId}).then(response=>{
        if (response.code == 200 && response.data){
          this.form = response.data
          this.contactIdDocCopy = this.ossUrl + this.form.contactIdDocCopy
          this.contactIdDocCopyBack = this.ossUrl + this.form.contactIdDocCopyBack
          this.businessAuthorizationLetter = this.ossUrl + this.form.businessAuthorizationLetter
          if (this.form.contactPeriodEnd == '长期'){
            this.cardTime = '长期'
          }else {
            this.cardTime = '定期'
          }
        }

      })
    },
    handleIdContactIdDocCopySuccess(res,file){
      this.form.contactIdDocCopy = res.data
      this.contactIdDocCopy = this.ossUrl + this.form.contactIdDocCopy

    },
    handleIdCopyBackSuccess(res,file){
      this.form.contactIdDocCopyBack = res.data
      this.contactIdDocCopyBack = this.ossUrl + this.form.contactIdDocCopyBack
    },
    handleIdBusinessAuthorizationLetterSuccess(res,file){
      this.form.businessAuthorizationLetter = res.data
      this.businessAuthorizationLetter = this.ossUrl + this.form.businessAuthorizationLetter
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    toNext(){
      //跳转下个页面且将数据进行保存
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         this.form.merchantId = this.merchantId
          if (this.cardTime == '长期'){
            this.form.contact_period_end = '长期'
          }
          this.form.isSubmit = true
          addContactInfo(this.form).then(res => {
            if (res.code === 200) {
              let applyData = {
                merchantId:this.merchantId
              }
              this.$router.push({path:'/merchantApplyInfo',query:{merchantId:this.merchantId,isSee:this.isSee}})
              this.$message({
                message: '操作成功',
                type: 'success'
              });
            }
          })
        }
      })
    },
    saveInfo(){
      this.form.merchantId = this.merchantId
      if (this.cardTime == '长期'){
        this.form.contact_period_end = '长期'
      }
      addContactInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 193px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.el-form-info{
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 8px;
}
</style>
